@media screen and (max-width:1800px) {
    .your_desginDiv img {
        height: 90px !important;
    }

    .your_desginDiv {
        left: 31%;
    }
}

@media screen and (max-width:1500px) {
    .article_side_view {
        width: 100%;
    }
}

@media screen and (max-width:1324px) {
    .headings {
        font-size: 1.5rem;
    }

    .line {
        height: 5px;
        background-color: #c9e465;
        width: 18%;
    }

    .content {
        font-size: 1.1rem;
    }

    .about_headngs {
        font-size: 2.1rem;
    }

    .detail_side {
        width: 25%;
    }

    .album_art {
        height: 360px;
    }

    .contact_btn {
        font-size: 0.9rem;
    }

}

@media screen and (max-width:1200px) {
    .card_title {
        font-size: 1rem;
    }

    .common_container {
        width: 85%;
    }
}

@media screen and (max-width:1042px) {
    .headings {
        font-size: 1.2rem;
    }

    .your_desginDiv img {
        height: 60px !important;
    }

}


@media screen and (max-width:1024px) {
    .cart_name {
        font-size: 1rem;
    }

    .menus_div {
        gap: 65px;
    }

    .card_title {
        font-size: 0.9rem;
    }

    .homart_txt {
        font-size: 1rem;
    }

    .popOverPages_main {
        width: 90%;
    }


}

@media screen and (max-width:992px) {
    .summary_cards {
        min-height: 100%;
        max-height: 100%;
      }
    .final_page_selected_arts{
        width: 100%;
      }
    .headings {
        font-size: 1rem;
    }

    .text_position {
        top: 79%
    }

    .content {
        font-size: 0.7rem;
    }

    .about_headngs {
        font-size: 0.7rem;
    }

    .detail_side {
        width: 35%;
    }

    .add_address_form {
        padding-inline: 60px;
    }

    .shipping_add_form {
        width: 100%;
    }

    .tab_txt {
        font-size: 1.4rem;
    }

    .article_continue_button {
        margin-top: 4px;
        margin-bottom: 31px;
    }

    .your_desginDiv {
        left: 37%;
    }
    .custom_art_text {
        width: 75vw;
      }
    .home_page_slider_images{
        height: 65vh;
    }
}

/* @media screen and (max-width:991px) {} */

@media screen and (max-width:768px) {

    .headings {
        font-size: 1.2rem;
    }

    .content {
        font-size: 1.2rem;
    }

    .about_headngs {
        font-size: 1.8rem;
    }

}

@media screen and (max-width:767px) {

    .detail_side {
        width: 45%;
    }
}

@media screen and (max-width:500px) {
    .siderbarPopOver {
        width: 386px;
    }
    .desgin_main_part {
        width: 100%;
    }
    .about_headngs {
        font-size: 1.5rem;
    }

    .product_image_summary {
        height: 206px !important;
    }

    .menus_div_footer {
        gap: 50px;
    }

    .bottom {
        height: 156px;
    }

    .headings {
        font-size: 1rem;
    }

    .homart_txt {
        font-size: 1.3rem;
    }


    .popOverPages_main {
        width: 94%;
    }

    .leftSide {
        text-align: center;
    }

    .order_detail_page {
        padding-inline: 10px;
    }


    .home_main_container {
        width: 96%;
    }

    .slider_responsive_text {
        width: 80%;
        text-wrap: wrap;
    }
}



@media screen and (max-width:425px) {
    .contact_form_input {
        width: 100%;
    }

}